import React from "react";

// Chakra imports
import { Badge, Icon, Flex, Button, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from '@chakra-ui/icons'

// Custom components
import Card from "../../../../components/card/Card.js";
import { VSeparator } from "../../../../components/separator/Separator";

// Assets
import { MdShare, MdEdit, MdDownload } from "react-icons/md";
import InvoiceBg from "../../../../assets/img/InvoiceBg.png";
import {maxLenghtString} from "../../../../utils/utils";

import { useNavigate } from 'react-router';

export default function Banner(props) {
  const { title, wallet, ...rest } = props;

  // Chakra Color Mode
  const navigate = useNavigate();

  return (
    <Card
      backgroundImage={InvoiceBg}
      backgroundRepeat='no-repeat'
      bgSize='cover'
      bgPosition='10%'
      p={{base:"20px", md:"60px"}}
      pt={{base:"20px", md:"60px"}}
      pb={{base:"70px", md:"100px"}}>
      <Flex mb={{ base: "0px", md: "50px" }} direction={{base:"column", md:"row"}}>
        <Flex direction='column' color='white' h='100%' w='100%' mb={{ base: "20px", md: "0px" }} cursor='pointer' onClick={() => navigate('/admin/home')}>
          <Text
            mt={{ base: "10px", md: "0px" }}
            fontSize='xl'
            fontWeight='bold'>
            <ArrowBackIcon></ArrowBackIcon>
            Back
          </Text>
          <Text
            mt={{ base: "10px", md: "0px" }}
            fontSize={{ base: "2xl", md: "32px", lg: "44px", xl: "44px" }}
            fontWeight='bold'>
            {title}
          </Text>
        </Flex>
        <VSeparator bg='whiteAlpha.300' mx={{ base: "10px", md: "40px" }} display={{base:"none", md:"flex"}} />
        <Flex direction='column' color='white' h='100%' w='100%'>
          <Text fontSize={{base:"md", md:"xl"}} mt={{ base: "10px", md: "0px" }} fontWeight='regular'>
            Wallet:<br></br>{maxLenghtString(wallet, 36)}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
