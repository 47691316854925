

import {useState, useEffect } from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";
import { ApiHeader } from '../../../_helpers/ApiHeader';

// Custom components
import Card from "../../../components/card/Card";
import Banner from "../../../views/dashboard/request/components/Banner";
import Content from "../../../views/dashboard/payPage/components/Content";

export default function TopUpFunds() {
  // Chakra Color Mode

  const [wallet, setWallet] = useState('')

  useEffect(()=>{
    isConnected();
  },[])

  const isConnected = () => {

    console.log(ApiHeader('auth'))

    fetch(process.env.REACT_APP_API_URL+'user/funds', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : 'goerli'
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setWallet(data.userData.address); 
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  return (
    <Card mt={{ base: "0px", md: "80px", xl: "80px" }} maxW='540px' mx='auto'>
      <Flex direction='column' width='stretch'>
        <Banner title='Pay by request'/>
        <Content wallet={wallet}/>
      </Flex>
    </Card>
  );
}
