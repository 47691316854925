import { useEffect, useState } from "react";
import { useNavigate } from 'react-router'
import { useLocation } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';
import jwt_decode from "jwt-decode";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  InputGroup,
  InputRightElement,
  Input,
  Icon,
  useColorModeValue,
  Text,
  useToast,
} from "@chakra-ui/react";

import DefaultAuth from "../../../layouts/auth/auth";

// Assets
import illustration from "../../../assets/img/auth.png";
import { MdRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const ResetPasswordPage = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const [resetToken, setResetToken] = useState("");
  const [expired, setExpired] = useState(false);
  const [username, setUsername] = useState("");

  const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();

    useEffect(()=>{
      setResetToken(query.get('token'));

      console.log(query.get('token'));

      const user = jwt_decode(query.get('token'));
      console.log(user);
      var d = new Date().getTime();
      if (user.exp*1000>d) {
        setExpired(true)
      }
      setUsername(user.email)
    },[])

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);


  const textColor = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [isLoading, setIsLoading] = useState(false);
  const [emailSend, setEmailSend] = useState(false);

  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const reclaimLink = (event) => {
    event.preventDefault();
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URL+'api/auth/forgetPassword', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        setEmailSend(true)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }


  const resetPassword = (event) => {
    event.preventDefault();
    if (password === '') {
      toast({
          title: 'Error.',
          description: "Password can not be empty",
          status: 'error',
        })
        return;
    }

    if (password !== password2) {
      toast({
          title: 'Error.',
          description: "Passwords do not match",
          status: 'error',
        })
        return;
    }
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URL+'api/auth/resetPassword', {
      method: 'POST',
      body: JSON.stringify({
        "password" : password
      }),
      headers: { 
        'Authorization': 'Bearer ' + resetToken,
        'Content-Type': 'application/json'
    }
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        toast({
          title: 'Password reset',
          description: "You have succesfully reset your password",
          status: 'success',
        })
        SetJwtAuth(data.token);
        setTimeout(() => {
          navigate('/admin/home')
        }, 1000);
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w='100%'
        maxW='max-content'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px", lg: "100px", xl: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", lg: "16vh", xl: "22vh" }}
        flexDirection='column'>
        {expired && (
          <>
            <Box me='auto' mb='34px'>
              <Heading
                color={textColor}
                fontSize={{ base: "3xl", md: "36px" }}
                mb='16px'>
                Reset password
              </Heading>
              <Text
                color={textColorSecondary}
                fontSize='md'
                w={{ base: "100%", lg: "456px" }}
                maxW='100%'>
                Please reset your password.
                The link expires in 1 hour after it was claimed.
              </Text>
            </Box>
            <Flex
              zIndex='2'
              direction='column'
              w={{ base: "100%", lg: "456px" }}
              maxW='100%'
              background='transparent'
              borderRadius='15px'
              mx={{ base: "auto", lg: "unset" }}
              me='auto'
              mb={{ base: "20px", md: "auto" }}
              align='start'>
              <FormControl>
                <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                    <Input
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "4px" }}
                        placeholder='Min. 8 characters'
                        mb='24px'
                        size='lg'
                        type={show ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdRemoveRedEye}
                        onClick={handleClick}
                        />
                    </InputRightElement>
                </InputGroup>
                <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Repeat Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                    <Input
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "4px" }}
                        placeholder='Min. 8 characters'
                        mb='24px'
                        size='lg'
                        type={show ? "text" : "password"}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdRemoveRedEye}
                        onClick={handleClick}
                        />
                    </InputRightElement>
                </InputGroup>
                <Button
                  fontSize='sm'
                  variant='brand'
                  fontWeight='500'
                  w='100%'
                  h='50'
                  mb='24px'
                  isLoading={isLoading}
                  onClick={resetPassword}>
                  Reset password
                </Button>
              </FormControl>
            </Flex>
          </>
        )}
        {(!expired && !emailSend) && (
            <>
              <Box me='auto' mb='34px'>
                <Heading
                  color={textColor}
                  fontSize={{ base: "3xl", md: "36px" }}
                  mb='16px'>
                  Link expired
                </Heading>
                <Text
                  color={textColorSecondary}
                  fontSize='md'
                  w={{ base: "100%", lg: "456px" }}
                  maxW='100%'>
                  The link has expired, please claim a new one and check inbox of your <span style={{color: textColorBrand}}>{username}</span> account.
                </Text>
              </Box>
              <Flex
                zIndex='2'
                direction='column'
                w={{ base: "100%", lg: "456px" }}
                maxW='100%'
                background='transparent'
                borderRadius='15px'
                mx={{ base: "auto", lg: "unset" }}
                me='auto'
                mb={{ base: "20px", md: "auto" }}
                align='start'>
                <FormControl>
                  <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    isLoading={isLoading}
                    onClick={reclaimLink}>
                    Get new link
                  </Button>
                </FormControl>
              </Flex>
            </>
          )}
        {(!expired && emailSend) && (
            <>
              <Box me='auto' mb='34px'>
                <Heading
                  color={textColor}
                  fontSize={{ base: "3xl", md: "36px" }}
                  mb='16px'>
                  Email send
                </Heading>
                <Text
                  color={textColorSecondary}
                  fontSize='md'
                  w={{ base: "100%", lg: "456px" }}
                  maxW='100%'>
                  Please check you inbox for an email with instructions on how to change the password.
                </Text>
              </Box>
            </>
          )}
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPasswordPage;
