import {
  Icon,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Button,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import Card from "../../../../../../components/card/Card.js";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {maxLenghtString} from "../../../../../../utils/utils";

function MostVisitedTable(props) {
  const { columnsData, tableData,  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");


  const onrowClick = (hash) => {
    let baseUrl;
    const network = 'goerli';
    if (network === 'goerli') {
      baseUrl = 'https://goerli.etherscan.io/tx/';
    } else if (network === 'mainnet') {
      baseUrl = 'https://etherscan.io/tx/';
    } else {
      // Handle other networks if needed
      // You can add more cases for different networks
      return;
    }

    // Create the full URL by concatenating the base URL and the transaction hash
    const url = baseUrl + hash;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  return (
    <Card mb={{ base: "20px", xl: "0px" }}>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align='center'
          justify='space-between'
          w='100%'
          px='10px'
          mb='20px'>
          <Text
            color={textColor}
            fontSize='lg'
            fontWeight='700'
            lineHeight='100%'>
            Transactions
          </Text>
        </Flex>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups?.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup?.headers?.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page?.map((row, index) => {
              // console.log(row);
              prepareRow(row);
              return (
                <Tr 
                  {...row.getRowProps()} 
                  key={index} 
                  cursor='pointer'
                  onClick={() => onrowClick(row?.original?.hash)}>
                  {row?.cells?.map((cell, index) => {
                    let data = "";
                    if (cell.column.id === "to_address") {
                      data = (
                        <Flex align='center'>
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {maxLenghtString(cell.value, 24)}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.id === "block_timestamp") {
                      const date = new Date(cell.value);
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='600'>
                          {`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}
                        </Text>
                      );
                    } else if (cell.column.id === "value") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='600'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.id === "hash") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='600'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.id === "status") {
                      data = (
                        <Text 
                          color={
                            cell.value === "received" ? "green.500" : "red.500"
                          }
                          fontSize='sm' 
                          fontWeight='600'>
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'
                        mt='20px !important'
                        py='10px !important'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    </Card>
  );
}

export default MostVisitedTable;
