import { useState} from "react"
import { Outlet, Navigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

export const IsValid = () => {
  const token = localStorage.getItem('paypangea_auth'); //

  if (!token) return false;
  
  const user = jwt_decode(token);
  var d = new Date().getTime();

  if (!user) {
    localStorage.removeItem("paypangea_auth");
    return false;
  }
    
  if (user.exp*1000<d) {
    localStorage.removeItem("paypangea_auth");
    return false;
  }

  return true;
};

export const SetJwtAuth = (jwt) => {
  localStorage.setItem("paypangea_auth", jwt);
};


export const Logout = (jwt) => {
  localStorage.removeItem("paypangea_auth");
};

export const JWTToken = () => {
  return IsValid() ? localStorage.getItem('paypangea_auth') : "";
}

export const GetUserData = () => {
  if (IsValid()) {
    const token = localStorage.getItem('paypangea_auth');
    const user = jwt_decode(token);
    console.log(user)

    return {
      address: user.address,
      username: user.username,
      expiry: user.exp*1000
    }
  } else {
    return null;
  }
}

export const ProtectRoutes = () => {
  return IsValid() ? <Outlet/> : <Navigate to='/auth/login' exact />
};