import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { NavLink } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
} from "@chakra-ui/react";

import DefaultAuth from "../../../layouts/auth/auth";

// Assets
import illustration from "../../../assets/img/auth.png";

const ForgotPasswordPage = () => {

  const toast = useToast();
  const navigate = useNavigate()

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailSend, setEmailSend] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'api/auth/forgetPassword', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        setEmailSend(true)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w='100%'
        maxW='max-content'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px", lg: "100px", xl: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", lg: "16vh", xl: "22vh" }}
        flexDirection='column'>
        <Box me='auto' mb='34px'>
          <Heading
            color={textColor}
            fontSize={{ base: "3xl", md: "36px" }}
            mb='16px'>
            Forgot your password?
          </Heading>
          <Text
            color={textColorSecondary}
            fontSize='md'
            w={{ base: "100%", lg: "456px" }}
            maxW='100%'>
            No problem. Just let us know your email address and we'll email you
            a password reset link that will allow you to choose a new one.
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", lg: "456px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}
          align='start'>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              type='email'
              placeholder='your email'
              mb='24px'
              size='lg'
              onChange={(e) => setUsername(e.target.value)}
            />
            {!emailSend &&
              <Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={isLoading}
                onClick={handleSubmit}>
                Email password reset link
              </Button>
            }
            {emailSend &&
              <Box me='auto' mb='34px'>
                <Heading
                  color={textColor}
                  fontSize={{ base: "3xl", md: "36px" }}
                  mb='16px'>
                  Email send
                </Heading>
                <Text
                  color={textColorSecondary}
                  fontSize='md'
                  w={{ base: "100%", lg: "456px" }}
                  maxW='100%'>
                  Please check you inbox for an email with instructions on how to change the password.
                </Text>
              </Box>
            }
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPasswordPage;
