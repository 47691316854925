import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BiSolidUser } from 'react-icons/bi';

// Admin Imports
import Dashboard from './views/dashboard/main/main';
import TransferPage from './views/dashboard/transactions/main';
import TopUpPage from './views/dashboard/topup/main';
import RequestPage from './views/dashboard/request/main';
import PayPage from './views/dashboard/payPage/main';

const routes = [  
	{
		name: 'Main',
		layout: '/admin',
		path: '/home',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:true,
		component: <Dashboard/>
	},
	{
		name: 'Transfer',
		layout: '/admin',
		path: '/transfer',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <TransferPage/>
	},
	{
		name: 'Top up',
		layout: '/admin',
		path: '/top-up',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <TopUpPage/>
	},
	{
		name: 'Request payment',
		layout: '/admin',
		path: '/request-payment',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <RequestPage/>
	},
	{
		name: 'Crypto Pay',
		layout: '/admin',
		path: '/pay',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <PayPage/>
	},
];

export default routes;
