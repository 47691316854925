import { useState } from "react";
import validator from "validator";
import { useNavigate } from 'react-router';
import { NavLink } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';

import { ethers } from 'ethers';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "../../../components/separator/Separator";
import DefaultAuth from "../../../layouts/auth/auth";
// Assets
import illustration from "../../../assets/img/auth.png";
import { BiWallet } from "react-icons/bi";
import { MdRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdEmail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';

const LoginPage = () => { 

  const toast = useToast();
  const navigate = useNavigate()

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [loginType, setLoginType] = useState('');
  const [stage, setStage] = useState(0);

  const selectLoginType = (logintype) => {
    setLoginType(logintype);
    setStage(1);
  }

  const loginSelection = () => {
    return (
    <>
        <Text fontSize="md" mt="10px" fontWeight='regular' 
            color={textColorSecondary}>
            How do you want to login?
        </Text>
        <Button 
            onClick={()=>selectLoginType('email')} 
            leftIcon={<Icon as={MdEmail} />}
            variant='brand' 
            mt="20px" 
            size="lg" 
            w="100%">
            Email
        </Button>
        
        <Button 
            onClick={()=>selectLoginType('phone')} 
            leftIcon={<Icon as={AiFillPhone} />}
            variant='brand' 
            mt="20px" 
            size="lg" 
            w="100%">
            Phone
        </Button>
    </>
    )
  }  

  const loginFields = () => {
    return (
    
      <FormControl>
      <FormLabel
        display='flex'
        ms='4px'
        fontSize='sm'
        fontWeight='500'
        color={textColorSecondary}
        mb='8px'>
        Enter your {loginType}<Text color={brandStars}>*</Text>
      </FormLabel>
      <Input
        variant='auth'
        fontSize='sm'
        ms={{ base: "0px", md: "0px" }}
        type='email'
        placeholder={loginType}
        mb='24px'
        fontWeight='500'
        size='lg'
        onChange={(e) => setUsername(e.target.value)}
      />

      <Flex w="100%" direction='row' gap={2}>
        <Button onClick={handleBack} 
            variant='outline' 
            size="lg" 
            w="100%">
            Back
        </Button>
        <Button
          fontSize='sm'
          variant='brand'
          fontWeight='500'
          w='100%'
          h='50'
          mb='24px'
          isLoading={isLoading}
          onClick={handleSubmit}>
          Start
        </Button>
      </Flex>
    </FormControl>
    )
  }  

  const handleBack = () => {
    setLoginType('');
    setStage(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (loginType === 'email') {
      if (validator.isEmail(username)) {
        loginEmail();
      } else {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Email is not valid",
          status: 'error',
        })
      }
    } else if (loginType === 'phone') {
      if (validator.isMobilePhone(username, 'any', { strictMode: true })) {
        loginPhone();
      } else if (validator.isMobilePhone(username, 'any', { strictMode: false })) {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Phone number must be in interantional format with + and country code",
          status: 'error',
        })
      } else {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Not recognised phone number",
          status: 'error',
        })
      }
    }
  }

  const loginEmail = () => {

    console.log(process.env.REACT_APP_API_URL)
    fetch(process.env.REACT_APP_API_URL+'auth/login-email', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        navigate(`/auth/verification?m=${btoa(username)}&t=${btoa(data.tokens)}`)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const loginPhone = () => {
    fetch(process.env.REACT_APP_API_URL+'auth/login-phone', {
      method: 'POST',
      body: JSON.stringify({
        "phone" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        navigate(`/auth/verification?m=${btoa(username)}&t=${btoa(data.tokens)}`)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
        title: 'Error.',
        description: "Error, please try again",
        status: 'error',
      })
      console.log("fetch error: " + err);
      setIsLoading(false);
    });
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            PayPangea
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Start using crypto payments in a few easy steps!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          {stage === 0 && loginSelection()}
          {stage === 1 && loginFields()}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default LoginPage;