
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router'
import { NavLink, useLocation } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';

import { ethers } from 'ethers';

import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  // Assets
  import { HSeparator } from "../../../components/separator/Separator";
  import DefaultAuth from "../../../layouts/auth/auth";
  // Assets
  import illustration from "../../../assets/img/auth.png";
  import { BiWallet } from "react-icons/bi";
  import { MdRemoveRedEye } from "react-icons/md";
  import { RiEyeCloseLine } from "react-icons/ri";
  
  const RegisterPage = () => { 

    const toast = useToast();
    const navigate = useNavigate();
    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleText = useColorModeValue("navy.700", "white");
    const googleHover = useColorModeValue(
      { bg: "gray.200" },
      { bg: "whiteAlpha.300" }
    );
    const googleActive = useColorModeValue(
      { bg: "secondaryGray.300" },
      { bg: "whiteAlpha.200" }
    );
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [referrer, setreferrer] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [checkboxSave, setCheckboxSave] = useState(false)


    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();

    useEffect(()=>{
        setreferrer(query.get('l'));

        console.log(query.get('l'))
    },[])

    const handleCheckBox = () => {
      setCheckboxSave(!checkboxSave)
      console.log(checkboxSave)
  }

    const handleSubmit = (event) => {
      event.preventDefault();
      if (username === '') {
        toast({
            title: 'Error.',
            description: "Please enter your email",
            status: 'error',
          })
          return;
      }

      if (password === '') {
        toast({
            title: 'Error.',
            description: "Please enter your password",
            status: 'error',
          })
          return;
      }

      if (!checkboxSave) {
        toast({
            title: 'Error.',
            description: "Please accept our terms and conditions",
            status: 'error',
          })
          return;
      }

      setIsLoading(true);
  
  
      fetch(process.env.REACT_APP_API_URL+'api/auth/signup', {
        method: 'POST',
        body: JSON.stringify({
          "email" : username,
          "password" : password,
          "referrer" : referrer,
        }),
        headers: ApiHeader('basic')
      })
      .then(response => { 
        return response.json();
      })
      .then(responseData => {
        return responseData;
      })
      .then(data => {
        setIsLoading(false);
        console.log(data)
        if (data.token) {
          SetJwtAuth(data.token);
          navigate(`/auth/verification?m=${btoa(username)}`)
        } else {
          console.log(data.message)
          toast({
              title: 'Error.',
              description: data.message,
              status: 'error',
            })
        }
      })
      .catch(err => {
        toast({
            title: 'Error.',
            description: "Error, please try again",
            status: 'error',
          })
          console.log("fetch error: " + err);
          setIsLoading(false);
      });
    }

    const Web3Auth = async (event) => {
      event.preventDefault();
      setIsLoading(true)

      try {
        if (window.ethereum) {
          const provider = new ethers.BrowserProvider(window.ethereum)
          const signer = await provider.getSigner()
          console.log(signer)
          provider.send("eth_requestAccounts", []).then(async () => {
            const address = await signer.getAddress()
            RegisterAddress(signer, address);
          })
        } else {
          toast({
              title: 'Error.',
              description: "Please open the page in Metamask mobile app.",
              status: 'error',
            })
          setIsLoading(false)
        }

      } catch (ex) {
        console.log(ex)
        setIsLoading(false)
        toast({
            title: 'Error.',
            description: "Error has occured.",
            status: 'error',
          })
      }
    }

    const RegisterAddress = async (signer, address) => {
      fetch(process.env.REACT_APP_API_URL+'api/auth/nonce', {
        method: 'POST',
        body: JSON.stringify({
          "wallet" : address,
          "referrer" : referrer,
        }),
        headers: ApiHeader('basic')
      })
      .then(response => { 
        return response.json();
      })
      .then(responseData => {
        return responseData;
      })
      .then(async data => {
        setIsLoading(false);
        console.log(data)
        if (data.status === 1) {

          const msg = `Proceed to PayPangea (${data.nonce}).`
          console.log(msg)
          const signature = await signer.signMessage(msg);
          
          fetch(process.env.REACT_APP_API_URL+'api/auth/web3auth', {
            method: 'POST',
            body: JSON.stringify({
              "wallet" : address,
              "nonce" : data.nonce,
              "msg" : msg,
              "signature" : signature,
            }),
            headers: ApiHeader('basic')
          })
          .then(response => { 
            return response.json();
          })
          .then(responseData => {
            return responseData;
          })
          .then(data1 => {
            setIsLoading(false);
            console.log(data1)
            if (data1.token) {
              SetJwtAuth(data1.token);
              navigate(`/admin/home`)
            } else {
              console.log(data1.message)
              toast({
                  title: 'Error.',
                  description: data1.message,
                  status: 'error',
                })
            }
          })
          .catch(err => {
            toast({
                title: 'Error.',
                description: "Error authenticating with smart wallet",
                status: 'error',
              })
              console.log("fetch error: " + err);
              setIsLoading(false);
          });

        } else {
          console.log(data.message)
          toast({
              title: 'Error.',
              description: data.message,
              status: 'error',
            })
        }
      })
      .catch(err => {
        toast({
            title: 'Error.',
            description: "Error authenticating with smart wallet",
            status: 'error',
          })
          console.log("fetch error: " + err);
          setIsLoading(false);
      });
    }

    return (
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          w='100%'
          maxW='max-content'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "8vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading
              color={textColor}
              fontSize={{ base: "34px", lg: "36px" }}
              mb='10px'>
              Register
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Enter your email and password to sign up!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <Button
              fontSize='sm'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg={googleBg}
              color={googleText}
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
              isLoading={isLoading}
              onClick={Web3Auth}>
              <Icon as={BiWallet} w='20px' h='20px' me='10px' />
              Connect Wallet
            </Button>
            <Flex align='center' mb='25px'>
              <HSeparator />
              <Text color={textColorSecondary} mx='14px'>
                or
              </Text>
              <HSeparator />
            </Flex>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                type='email'
                placeholder='Email Address'
                mb='24px'
                size='lg'
                onChange={(e) => setUsername(e.target.value)}
              />
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "4px" }}
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='start'>
                  <Checkbox
                    id='agree-terms'
                    colorScheme='brandScheme'
                    me='10px'
                    mt='3px'
                    isChecked={checkboxSave}
                    onChange={() => handleCheckBox()}
                  />
                  <FormLabel
                    htmlFor='agree-terms'
                    mb='0'
                    fontWeight='normal'
                    color={textColor}
                    fontSize='sm'>
                    By creating an account means you agree to the{" "}
                    <Link
                      href='https://coinclash.games/terms.html'
                      fontWeight='500'>
                      Terms and Conditions,
                    </Link>{" "}
                    and our{" "}
                    <Link
                      href='https://coinclash.games/privacypolicy.html'
                      fontWeight='500'>
                      Privacy Policy
                    </Link>
                  </FormLabel>
                </FormControl>
              </Flex>
              <Button
                variant='brand'
                fontSize='14px'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={isLoading}
                onClick={handleSubmit}>
                Create my account
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='sm'>
                Already a member?
                <NavLink to='/auth/login'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    Sign in
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    );
  }
  
  export default RegisterPage;
  