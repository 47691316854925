import {useState, useEffect} from "react";
import { useNavigate } from 'react-router';
import QRCode from 'qrcode.react';

// Chakra imports
import { 
  Button,
  Input, 
  Flex, 
  Text, 
  useToast,
  useBreakpointValue,
  Icon, 
  IconProps,
  useColorModeValue } from "@chakra-ui/react";

// Custom components

import LoginModal from '../../../../views/modals/login/login';
import PinInputModal from '../../../../views/modals/pin/pin';
import OutcomeModal from '../../../../views/modals/outcome/outcome';
import Overlay from '../../../../views/modals/overlay/overlay';

import SetPinModal from '../../../../views/modals/setcode/setcode';
import Card from "../../../../components/card/Card.js";
import { VSeparator, HSeparator } from "../../../../components/separator/Separator";
import SelectField from "../../../../components/fields/SelectField";
import { ApiHeader } from '../../../../_helpers/ApiHeader';

import { FaCheckCircle } from 'react-icons/fa';

// Assets

export default function Content(props) {
  const { wallet, ...rest } = props;



  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgCard = useColorModeValue("white", "navy.700");
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");


  const currentPageURL = window.location.search;
  const searchParams = new URLSearchParams(currentPageURL);

  const requestTkn = searchParams.get('tkn'); 
  const payeeEmail = searchParams.get('email'); 
  const payeePhone = searchParams.get('phone'); 

  console.log(payeeEmail, payeePhone)

  const [receiverWallet, setReceiverWallet] = useState('');
  const [network, setNetwork] = useState('');
  const [amount, setAmount] = useState('');
  const [token, setToken] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [transactionFee, setTransactionFee] = useState('-');
  const [walletUsername, setWalletUsername] = useState('');
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const walletDisplayText = useBreakpointValue({
    base: `${receiverWallet.substr(0,8)}...${receiverWallet.slice(-6)}`,
    md: receiverWallet,
  });

  const VerifiedIcon = () => {
    if (isUserVerified) {
      return (
        <Icon as={FaCheckCircle} boxSize={4} color="blue.400" />
      );
    }

    return (<></>);
  };


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payTkn, setPayTkn] = useState('');
  const [payOutcome, setPayOutcome] = useState('');
  const [outcomeMessage, setOutcomeMessage] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openOutcomeModal = () => {
    setIsOutcomeModalOpen(true);
  };

  const closeOutcomeModal = () => {
    setIsOutcomeModalOpen(false);

    navigate(`/admin/home`);
  };

  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const openLoginModal = () => {
    setIsLoginOpen(true);
  };

  const closeLoginModal = (isNew) => {
    console.log(isNew);

    setIsLoginOpen(false);

    if (!isNew) {
      payToCryptoAddress();
    } else {
      openPinModal();
    }
  };


  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  const openPinModal = () => {
    setIsPinModalOpen(true);
  };

  const closePInModal = () => {
    setIsPinModalOpen(false);
  };

  const pinSet = () => {
    navigate('/admin/home');
  }

  useEffect(()=>{
    getTransactionData()
  },[])

  const getTransactionData = () => {
    console.log(wallet)

    fetch(process.env.REACT_APP_API_URL+'pay/pay-by-request', {
      method: 'POST',
      body: JSON.stringify({
        "tkn" : requestTkn
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data.payData.receiver)
        setReceiverWallet(data.payData.receiver)
        setNetwork(data.payData.chain)
        setAmount(data.payData.amount)
        setToken(data.payData.token)
        setTokenAddress(data.payData.tokenAddress)
        setIsUserVerified(data.payData.verified)
        setWalletUsername(data.payData.username)
        setTitle(data.payData.title)
        setText(data.payData.text)
        getTransactionFee();
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const getTransactionFee = (token_address) => {

    console.log(`getTransactionFee ${token_address}`)
 
    if (!token_address) {
      token_address = '0x00'
    }

    fetch(process.env.REACT_APP_API_URL+'pay/estimate-gas', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : 'goerli',
        "amount": "0",
        "token": token_address
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setTransactionFee(data.estimatedGas)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }


  const handleSubmit = () => {
    setIsLoginOpen(true)
  };


  const payToCryptoAddress = () => {
    setIsOverlay(true);

    fetch(process.env.REACT_APP_API_URL+'pay/send-address', {
      method: 'POST',
      body: JSON.stringify({
        "address": receiverWallet,
        "amount": amount.toString(),
        "token": tokenAddress,
        "chain" : 'goerli',
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsOverlay(false);
      console.log(data)

      if (data.status === 1) {
        setPayTkn(data.paymentData.tkn)

        openModal();

      } else if (data.status === 2) {
        setPayOutcome('FAILURE');
        setOutcomeMessage(`${data.message}.\nPlease top up your account.`)

        openOutcomeModal(true);
      }
      
    })
    .catch(err => {
        setIsOverlay(false);
        console.log("fetch error: " + err);
    });
  }

  const submitPin = (submitedPin) => {
    console.log(`submitedPin ${submitedPin}`)
    setIsModalOpen(false);
    confirmPay(submitedPin)
  };


  const confirmPay = (pin) => {
    setIsOverlay(true);

    fetch(process.env.REACT_APP_API_URL+'pay/verify-payment', {
      method: 'POST',
      body: JSON.stringify({
        "tkn": payTkn,
        "passkey": pin,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setPayOutcome('SUCCESS');
      } else if (data.status === 2) {
        setPayOutcome('FAILURE');
        setOutcomeMessage(`${data.message}.\nPlease top up your account.`)
      } else {
        setPayOutcome('FAILURE');
      }
      openOutcomeModal(true);
      setIsOverlay(false);
    })
    .catch(err => {
        console.log("fetch error: " + err);

      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }

  return (
    <>
      <Flex direction='column' p={{base:"60px 10px 10px", md:"10px 40px"}}>
        <Card
          backgroundRepeat='no-repeat'
          bg={bgCard}
          p='30px'
          mb='30px'
          mt='-100px'>

          
          <Flex direction={"column"}>
            <Flex 
            direction='column' 
            me='auto' 
            width={"100%"}
            mb={{base:"30px", md:"0px"}}>

            <Text
                color={textColor}
                fontWeight='bold'
                fontSize='24px'
                mb={'20px'}
                textAlign={'left'}>
                {title}
              </Text>   
              <Text
                color={textColor}
                fontWeight='bold'
                fontSize='sm'
                mb={'20px'}
                textAlign={'left'}>
                {text}
              </Text>   
              <Text
                color={textColor}
                fontWeight='bold'
                fontSize='38px'
                textAlign={'right'}>
                {amount} {token}
              </Text>   
              <Text
                color={textColor}
                fontWeight='bold'
                fontSize='sm'
                textAlign={'right'}>
                {'Transaction fee'}
              </Text>   
              <Text
                color={textColor}
                fontWeight='400'
                fontSize='sm'
                textAlign={'right'}>
                {transactionFee}
              </Text> 
              <Text
                color={textColor}
                fontWeight='bold'
                fontSize='sm'
                textAlign={'right'}>
                {'Network'}
              </Text>   
              <Text
                color={textColor}
                fontWeight='400'
                fontSize='sm'
                textAlign={'right'}>
                {network}
              </Text>   
              <Text
                color={textColor}
                mt={"20px"}
                fontWeight='bold'
                fontSize='sm'
                textAlign={'left'}>
                {'Payable to:'}
              </Text>   
              <Text
                color={textColor}
                fontWeight='400'
                fontSize='sm'
                textAlign={'left'}>
                {walletDisplayText} <VerifiedIcon />
              </Text>
              <Text
                color={textColor}
                fontWeight='bold'
                fontSize='sm'
                mt={"8px"}
                mb={"20px"}
                textAlign={'left'}>
                {walletUsername}
              </Text>
            </Flex>
            <Button onClick={handleSubmit} 
                variant='brand' 
                size="lg" 
                mt="20px"
                w="100%">
                Pay
              </Button>
          </Flex>
        </Card>
      </Flex>

      <LoginModal isOpen={isLoginOpen} onClose={closeLoginModal} email={payeeEmail} phone={payeePhone}/>

      <SetPinModal isOpen={isPinModalOpen} onClose={closePInModal} pinSet={pinSet}/>

      <PinInputModal isOpen={isModalOpen} onClose={closeModal} submitPin={submitPin} recepient={receiverWallet} amount={amount + " " + token}/>

      <OutcomeModal isOpen={isOutcomeModalOpen} onClose={closeOutcomeModal} outcome={payOutcome} message={outcomeMessage}/>

      <Overlay isOpen={isOverlay} text={'Processing ...'}/>
    </>
  );
}
