// src/PinInputModal.js
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  ModalHeader,
  VStack,
  Grid,
  GridItem,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';

import InvoiceBg from "../../../assets/img/InvoiceBg.png";
import Card from "../../../components/card/Card.js";

const PinInputModal = ({ isOpen, onClose, submitPin, recepient, amount }) => {
  const [pin, setPin] = useState('');

  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  

  const handleNumberClick = (number) => {
    if (pin.length < 6) {
      setPin(pin + number);
    }
  };

  const handleBackspaceClick = () => {
    setPin(pin.slice(0, -1));
  };

  const handleClearClick = () => {
    setPin('');
  };

  const handleSubmit = () => {
    if (pin.length === 6) {
      submitPin(pin);
      setPin('');
    }
  };

  return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        isOpen={isOpen} 
        onClose={onClose} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>

            <Card
              backgroundImage={InvoiceBg}
              backgroundRepeat='no-repeat'
              bgSize='cover'
              bgPosition='10%'
              borderRadius="5px 5px 0 0" 
              p="20px"
              pb="40px">
              <Flex mb={{ base: "0px", md: "0px" }} direction="column">
                <Grid
                  templateColumns="1fr 2fr" // 1/3 and 2/3 width
                  gap={2} // Adjust the gap between grid items
                >
                  <GridItem colSpan={1}>
                    <Text fontSize="md" mt="0px" fontWeight='regular'>
                      Recepient:
                    </Text>
                    <Text fontSize="md" mt="0px" fontWeight='regular'>
                      Amount:
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Text fontSize="md" mt="0px" fontWeight='regular'>
                      {recepient.length > 30 ? `${recepient.substr(0,8)}...${recepient.slice(-6)}` : recepient}
                    </Text>
                    <Text fontSize="md" mt="0px" fontWeight='regular'>
                      {amount}
                    </Text>
                  </GridItem>
                </Grid>
              </Flex>
            </Card>
            <ModalHeader>Enter your 6-digit PIN</ModalHeader>
            <VStack spacing={4} p={4}>
                <FormControl mb="20px">
                    <Flex justify="center">
                        <PinInput value={pin} onChange={(value) => setPin(value)}>
                            {Array.from({ length: 6 }, (_, i) => (
                                <PinInputField
                                    key={i}
                                    fontSize="24px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '63px' }}
                                    w={{ base: '63px', md: '63px' }}
                                    me="10px"
                                />
                            ))}
                        </PinInput>
                    </Flex>
                </FormControl>
                <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {Array.from({ length: 9 }, (_, i) => (
                    <GridItem key={i} colSpan={1}>
                        <Button onClick={() => handleNumberClick(i + 1)} size="lg" w="100%">
                        {i + 1}
                        </Button>
                    </GridItem>
                    ))}
                    <GridItem colSpan={1}>
                    <Button onClick={handleClearClick} size="lg" w="100%">
                        Clear
                    </Button>
                    </GridItem>
                    <GridItem colSpan={1}>
                    <Button onClick={() => handleNumberClick(0)} size="lg" w="100%">
                        0
                    </Button>
                    </GridItem>
                    <GridItem colSpan={1}>
                    <Button onClick={handleBackspaceClick} size="lg" w="100%">
                        Back
                    </Button>
                    </GridItem>
                </Grid>

                <Flex w="100%" direction='row' gap={2}>
                  <Button onClick={onClose} 
                      variant='outline' 
                      size="lg" 
                      w="100%">
                      Cancel
                  </Button>
                  <Button onClick={handleSubmit} 
                      variant='brand' 
                      size="lg" 
                      w="100%">
                      Pay
                  </Button>
                </Flex>
            </VStack>
        </ModalContent>
    </Modal>
  );
};

export default PinInputModal;
