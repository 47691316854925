// src/PinInputModal.js
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  ModalHeader,
  VStack,
  Grid,
  GridItem,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';

import InvoiceBg from "../../../assets/img/InvoiceBg.png";
import Card from "../../../components/card/Card.js";
import { ApiHeader } from '../../../_helpers/ApiHeader';

const PinSetModal = ({ isOpen, onClose, pinSet }) => {
  const [pin, setPin] = useState('');
  const [repeatPin, setRepeatPin] = useState('');
  const [stage, setStage] = useState(0);
  const [modalText, setModalText] = useState('Select your 6-digit PIN');

  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  

  const handleNumberClick = (number) => {
    if (stage === 0) {
        if (pin.length < 6) {
          setPin(pin + number);
        }
    } else if (stage === 1) {
        if (repeatPin.length < 6) {
            setRepeatPin(repeatPin + number);
        }
    }
  };

  const handleBackspaceClick = () => {
    if (stage === 0) {
        setPin(pin.slice(0, -1));
    } else if (stage === 1) {
        setRepeatPin(repeatPin.slice(0, -1));
    }
  };

  const handleClearClick = () => {
    if (stage === 0) {
        setPin('');
    } else if (stage === 1) {
        setRepeatPin('');
    }
  };


  const savePin = () => {
    fetch(process.env.REACT_APP_API_URL+'user/set-passcode', {
      method: 'POST',
      body: JSON.stringify({
        "passkey" : pin
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        pinSet();
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const handleSubmit = () => {
    if (stage === 0) {
        if (pin.length === 6) {
            setStage(1);
            setModalText('Re-enter your PIN');
        }
    } else if (stage === 1) {
        if (pin === repeatPin) {
            savePin();
        } else {
            console.log(pin, repeatPin)
        }
    }
  };

  const handelSetPin = (pin) => {
    stage === 0 ? setPin(pin) : setRepeatPin(pin);
  }

  const pinValue = () => {
    return stage === 0 ? pin : repeatPin;
  }

  const buttonText = () => {
    return stage === 0 ? 'Confirm' : 'Save code';
  }

  return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        closeOnOverlayClick={false} 
        isLazy={true}
        isOpen={isOpen} 
        onClose={onClose} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>

            <Card
              backgroundImage={InvoiceBg}
              backgroundRepeat='no-repeat'
              bgSize='cover'
              bgPosition='10%'
              borderRadius="5px 5px 0 0" 
              p="20px"
              pb="30px">
              <Flex mb={{ base: "0px", md: "0px" }} direction="column">
                <Text fontSize="xl" mt="0px" fontWeight='bold'>
                    Set your 6-digit PIN:
                </Text>
              </Flex>
            </Card>
            <ModalHeader>{modalText}</ModalHeader>
            <VStack spacing={4} p={4}>
                <FormControl mb="20px">
                    <Flex justify="center">
                        <PinInput value={pinValue()} onChange={(value) => handelSetPin(value)}>
                            {Array.from({ length: 6 }, (_, i) => (
                                <PinInputField
                                    key={i}
                                    fontSize="24px"
                                    color={textColor}
                                    borderRadius="16px"
                                    borderColor={borderColor}
                                    h={{ base: '63px', md: '63px' }}
                                    w={{ base: '63px', md: '63px' }}
                                    me="10px"
                                />
                            ))}
                        </PinInput>
                    </Flex>
                </FormControl>
                <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                    {Array.from({ length: 9 }, (_, i) => (
                    <GridItem key={i} colSpan={1}>
                        <Button onClick={() => handleNumberClick(i + 1)} size="lg" w="100%">
                        {i + 1}
                        </Button>
                    </GridItem>
                    ))}
                    <GridItem colSpan={1}>
                    <Button onClick={handleClearClick} size="lg" w="100%">
                        Clear
                    </Button>
                    </GridItem>
                    <GridItem colSpan={1}>
                    <Button onClick={() => handleNumberClick(0)} size="lg" w="100%">
                        0
                    </Button>
                    </GridItem>
                    <GridItem colSpan={1}>
                    <Button onClick={handleBackspaceClick} size="lg" w="100%">
                        Back
                    </Button>
                    </GridItem>
                </Grid>

                <Flex w="100%" direction='row' gap={2}>
                  <Button onClick={handleSubmit} 
                      variant='brand' 
                      size="lg" 
                      w="100%">
                      {buttonText()}
                  </Button>
                </Flex>
            </VStack>
        </ModalContent>
    </Modal>
  );
};

export default PinSetModal;
