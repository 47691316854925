import { 
    Button,
    Select, 
    FormControl,
    FormErrorMessage,
    Flex,
    FormLabel,
    useColorModeValue,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    List,
    ListItem,
    Text } from "@chakra-ui/react";
import React, { useState } from "react";

function ButtonSelect(props) {
  const { baseOptions, label, mb, onChange, isInvalid, errorMessage, ...rest } = props;
  let { fixedElements,  } = props;
  
  let options=[];
  let otherOptions=[];

  if (!baseOptions || baseOptions.length === 0) {
    fixedElements=[];
  }

  if (fixedElements.length>0) {
    options = [baseOptions[0], ...fixedElements];
    otherOptions = baseOptions.slice(1).filter(element => !fixedElements.includes(element));
  } else {
    options = baseOptions.slice(0, 3);
    otherOptions = baseOptions.slice(3);
  }

  const [selectedOption, setSelectedOption] = useState(baseOptions[0]);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const [isModalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleOptionSelect = (option) => {
    handleOptionChange(option);
    handleModalClose();
  };

  const handleOptionChange = (newOption) => {
    setSelectedOption(newOption);
    onChange(newOption);
  };

  return (
    <FormControl 
      mb={mb ? mb : "30px"}
      isInvalid={isInvalid}>
        <FormLabel
            display='flex'
            ms='10px'
            fontSize='sm'
            color={textColorPrimary}
            fontWeight='bold'
            _hover={{ cursor: "pointer" }}>
            {label}
            </FormLabel>
        <Flex 
            direction='row' 
            justifyContent='left'
            gap='10px'>
            {options.map((option, i) => (
                <Button
                    key={`${option}-${i}`}
                    fontSize='md'
                    fontWeight='400'
                    w='22%'
                    h='64px'
                    colorScheme={selectedOption === option ? "blue" : "gray"}
                    onClick={() => handleOptionChange(option)}
                >
                    {option}
                </Button>
            ))}
        {otherOptions.length > 0 && (
            <Button
                fontSize='md'
                fontWeight='400'
                w='22%'
                h='64px'
                colorScheme={!options.includes(selectedOption) ? "blue" : "gray"}
                onClick={() => handleModalOpen()}
            >
                {options.includes(selectedOption)? 'Other' : selectedOption}
            </Button> 
        )}


        <Modal isCentered isOpen={isModalOpen} onClose={handleModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select an option</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <List spacing={3}>
                {otherOptions.map((option, i) => (
                  <ListItem 
                    key={`${option}-${i}`}
                    cursor="pointer"
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </ListItem>
                ))}
              </List>
            </ModalBody>
          </ModalContent>
        </Modal>
        
        </Flex>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

export default ButtonSelect;
